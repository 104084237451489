import React, { useState } from "react";
import Modal from "../../UI_elements/modal";
import CreateDraftItemForm from "../../forms/createDraftItemForm";
import ItemStatus from "../../UI_elements/itemStatus";
import Button from "../../UI_elements/button";

const YourItemActions = ({ state, tracer, item, aidDetails, onSuccess }) => {
  const [newDraftOrRelease, setNewDraftOrRelease] = useState();


  console.log(item)
  const buttonColourCSS =
    state === "released"
      ? "border-taskinatorGreen text-taskinatorWhite bg-taskinatorGreen   hover:opacity-75  "
      : state === "draft"
      ? " border-taskinatorBlue text-taskinatorWhite bg-taskinatorBlue hover:opacity-75"
      : " border-taskinatorPurple text-taskinatorWhite bg-taskinatorPurple hover:opacity-75";

  return (
    <>
      <div className="flex items-center text-xs  ">
        <Button
          onClick={() => setNewDraftOrRelease(`${state}`)}
          label={`Attach ${state}`}
          customClasses={`border ${buttonColourCSS} uppercase items-center h-8 px-2 hover:text-taskinatorWhite border rounded`}
        />
      </div>

      {newDraftOrRelease && (
        <Modal
          title={`${newDraftOrRelease === "released" ? "Attach Released" : newDraftOrRelease === "draft" ? "Attach Draft" : "Attach Mark Up"}`}
          local={() => {
            setNewDraftOrRelease();
          }}
        >
          <CreateDraftItemForm
            itemData={item}
            tracerId={tracer ? tracer._id : null}
            iid={item._id}
            aid={aidDetails ? aidDetails._id : null}
            onSuccess={() => {
              setNewDraftOrRelease();
              onSuccess();
            }}
            state={newDraftOrRelease}
          />
        </Modal>
      )}
    </>
  );
};

export default YourItemActions;
