import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../../contexts/appContext";
import Button from "../../UI_elements/button";
import Modal from "../../UI_elements/modal";
import StringShortenDisplay from "../../UI_elements/stringShortenDisplay";
import Tag from "../../UI_elements/tag";
import JobDetailsForm from "../../forms/jobDetailsForm";
import JobOnHold from "../task/JobOnHold";
import JobRequests from "../task/jobRequests";
import JobDetailsDisplay from "./jobDetailsDisplay";
import { JobModalContext } from "./jobModalContext";
import JobModalDropDownContainer from "./jobModalDropDownContainer";
import LowerCaseTrim from "../../../utils/lowerCaseAndTrim";
import JobFormatter from "../../../utils/jobFormatter";
import WorkflowGroup from "./workflowGroup";
import AddFileSetToJob from "./addFileSetToJob";
import { useInputV4 } from "../../../stateManagement/useInputV4";
import { VALIDATOR_MINLENGTH } from "../../forms/validators/validators";
import InputV4 from "../../inputs/input_V4";
import axios from "axios";
import { useInit } from "../../hooks/useInit";
import CountUpTimer from "../../../utils/countUpTimer";
import ApproveRejectForm from "../../forms/approveRejectForm";
import JobQuestions from "./jobQuestions";
import UserDisplayBubble from "../../UI_elements/userDisplayBubble";
import NewInstruction from "../../forms/newInstruction";
import TaskDisplay from "../../UI_elements/taskDisplay";
import ViewFilesOnTask from "./viewFilesOnTask";
import DateDisplay from "../../UI_elements/dateDisplay";
import StandardToggle from "../../UI_elements/standardToggle";
import { MdClose, MdWarning } from "react-icons/md";
const JobModalDetailDisplay = ({ allJobsSelectedJobData, allJobsViewHandler }) => {
  const [buttons, setButtons] = useState([]);
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(2);
  const [addFileSet, setAddFileSet] = useState();
  const [tasksDetails, setTaskDetails] = useState();
  const [workflowGroupId, setWorkflowGroupId] = useState();
  const [confirmDelete, setConfirmDelete] = useState();
  const [questions, setQuestions] = useState();
  const [minOnly, setIsMineOnly] = useState(true);
  const appData = useContext(AppContext);
  const [selectedTask, setSelectedTask] = useState();
  const [addTask, setAddTask] = useState();

  const { inputState, inputHandler } = useInputV4({
    inputs: { search: { value: "", isValid: false } },
    isValid: false,
  });
  const filter = inputState.inputs.search.value;

  const jobData = !allJobsSelectedJobData
    ? Object.values(JobFormatter(appData.jobs, "", appData)).find((job) => job._id.toString() === appData.viewJob.jobId)
    : Object.values(JobFormatter(allJobsSelectedJobData, "", appData)).find((job) => job._id.toString() === allJobsViewHandler);

  const incomingSelectedTaskData = jobData?.tasks?.find((task) => task?._id.toString() === appData?.viewJob?.taskId?.toString());
  const jobIDtoUse = appData?.viewJob?.jobId ? appData.viewJob.jobId : allJobsViewHandler;

  const [selectedTaskIds, setSelectedTaskIds] = useState(
    appData?.viewJob?.taskId
      ? {
          [appData.viewJob.taskId]: {
            files: incomingSelectedTaskData?.submittedFiles?.map((file) => {
              return { revisionTrackingId: file?.revisionTrackingId, submissionId: file?.submissionId };
            }),
            workflowId: incomingSelectedTaskData?.workflowData?._id?.toString(),
            primaryTask: incomingSelectedTaskData?.primaryTaskDetails?._id?.toString(),
          },
        }
      : {}
  );

  /// this code resets the mmodal view for the job when a task is removed

  useEffect(() => {
    if (!jobData) {
      appData.setJobViewHandler();
    }

    // const checkTasks = Object.values(jobData?.tasksMap?.tasks);

    // if (!checkTasks[0]._id || checkTasks.length === 1) {
    //   setSelectedTask(checkTasks[0]._id);
    // }
  }, [jobData]);

  const isManager = [process.env.REACT_APP_ACCESS_A, process.env.REACT_APP_ACCESS_O, process.env.REACT_APP_ACCESS_M].includes(appData.workspaceDataV4.role);
  const isManagingThisJob = jobData?.jobDetails?.managers?.find((manager) => manager._id.toString() === appData.workspaceDataV4.user_id);

  const [editJobDetails, setEditJobDetails] = useState(false);

  const CompleteHandler = () => {
    initStarted(1);

    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/job/markjobascompleted/${jobData?._id}/${jobData?.completed ? "undo" : "completed"}`,
        { data: true },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        console.log(response.data);
        initCompleted(1);
      })
      .catch((err) => {
        console.log(err);
        return initErrors(err?.response?.data?.message ?? err?.message ?? "", 1);
      });
  };

  if (jobData) {
    return (
      <JobModalContext.Provider
        value={{
          jobId: jobIDtoUse,
          tasksDetails,
          setTaskDetails,
          workflowGroupId,
          setWorkflowGroupId,
          jobCompleted: jobData?.completed,
          filter: filter,
          selectedTaskIds: selectedTaskIds,
          setSelectedTaskIds: setSelectedTaskIds,
          tasks: jobData.tasks,
        }}
      >
        <div className={`  h-full overflow-auto flex flex-col text-sm`}>
          <div className="flex">
            <div className="w-full h-full">
              <div className="">
                <JobModalDropDownContainer
                  textColour={"text-taskinatorWhite"}
                  headerContent={
                    <div className="flex justify-between items-center w-full">
                      <div className="flex grow justify-start items-center">
                        <div className="pl-1">Job:</div>
                        <b className="order-taskinatorWhite border rounded py-1 px-2 border-taskinatorDarkGrey ml-1">{StringShortenDisplay(30, jobData?.jobDetails?.ref)}</b>
                        {/* <b className=" order-taskinatorWhite pl-2"> Job Details </b> */}
                        <div className={`flex   ml-0 lg:pr-2 text-base font-light `}>
                          <div className={`flex items-center justify-center lg:py-0 py-2`}>
                            <div className="flex">
                              {jobData?.jobDetails?.taskTags?.map((tag, index) => {
                                if (index < 2) return <Tag tagData={tag} key={index} />;
                              })}
                            </div>
                          </div>
                        </div>
                        {isManagingThisJob && <div className="border-taskinatorOrange border  px-2 text-xs text-taskinatorOrange py-0.5 font-medium rounded uppercase">You're Managing This Job</div>}
                        {jobData?.completed && (
                          <div className="text-xs  normal-case flex items-center">
                            Marked as <div className="font-bold uppercase text-taskinatorGreen border border-taskinatorGreen rounded py-1 px-0.5 ml-1">completed</div>{" "}
                            <div className="px-1">{<CountUpTimer startDate={jobData.completedDate} />}</div> ago by:{" "}
                            <div className="font-semibold flex capitalize pl-1">
                              {jobData.completedBy.firstName}
                              <div className="pl-1">{jobData.completedBy.lastName}</div>
                            </div>
                          </div>
                        )}
                        {jobData?.jobDetails?.onHold && (
                          <div
                            className={`border-taskinatorOrange text-taskinatorDarkGrey animate-pulse bg-taskinatorOrange font-medium uppercase h-8 flex items-center justify-center text-xs px-2 border rounded ml-2`}
                          >
                            ON HOLD
                          </div>
                        )}{" "}
                      </div>
                    </div>
                  }
                  borderColour={"border-taskinatorDarkGrey"}
                  backgroundColour={"bg-taskinatorDarkGrey hover:opacity-90 border border-taskinatorDarkGrey  "}
                >
                  <div className="flex w-full grow bg-taskinatorWhite p-2 rounded-lg border-taskinatorMedGrey mb-1 border">
                    <div className="flex items-center  justify-start   normal-case  pr-2 mr-2">
                      <div className="text-taskinatorDarkGrey pr-1 md:text-sm font-medium">Due Date:</div>
                      <div className="md:text-sm">{DateDisplay(jobData?.jobDetails?.dueDate)}</div>
                    </div>
                    <div className="flex items-center  justify-start   normal-case  pr-2 mr-2">
                      <div className="text-taskinatorDarkGrey pr-1 md:text-sm font-medium">Priority:</div>
                      <div className="md:text-sm">
                        {" "}
                        <Tag tagData={{ type: "priority", name: jobData?.jobDetails?.priority ?? "Standard" }} />
                      </div>
                    </div>
                    <div className="flex items-center  justify-start   normal-case  pr-2 mr-2">
                      <div className="text-taskinatorDarkGrey pr-1 md:text-sm font-medium">Created By:</div>
                      <div className=" md:text-sm capitalize ">
                        {jobData?.jobDetails?.createdBy.split(" ")[0]} {jobData?.jobDetails?.createdBy.split(" ")[1][0]}
                      </div>
                    </div>
                    <div className="flex items-center  justify-start   normal-case  pr-2 mr-2">
                      <div className="text-taskinatorDarkGrey pr-1 md:text-sm font-medium">Active For:</div>
                      <div className="text-base md:text-sm ">{<CountUpTimer startDate={jobData?.jobDetails?.createdDate} />}</div>
                    </div>
                    {isManager && (
                      <div className="flex grow items-center justify-end mb-1">
                        <>
                          {!jobData?.completed && (
                            <JobOnHold
                              jobId={jobData?._id}
                              activator
                              taskDetails={jobData?.jobDetails}
                              // tracer={tracer}
                              // getData={() => getData()}
                            />
                          )}
                          {!jobData?.completed && (
                            <div className="">
                              <Button
                                onClick={() => setConfirmDelete(true)}
                                isLoading={initState[1].started}
                                label={jobData?.completed ? "Re-Open Job" : "Close Job"}
                                customClasses={`${
                                  jobData?.completed
                                    ? "border-taskinatorOrange border text-taskinatorOrange opacity-50"
                                    : "border-taskinatorRed border text-taskinatorRed hover:bg-taskinatorRed hover:text-white"
                                }    h-8 text-xs uppercase ml-2`}
                              />
                            </div>
                          )}
                          {!jobData?.completed && (
                            <div className="">
                              <Button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setEditJobDetails(true);
                                }}
                                label="Edit Details"
                                customClasses={`border-taskinatorMedGrey text-taskinatorMedGrey hover:bg-taskinatorMedGrey hover:text-taskinatorWhite uppercase h-8 flex items-center justify-center text-xs px-2 border rounded ml-2`}
                              />
                            </div>
                          )}
                        </>
                      </div>
                    )}{" "}
                  </div>
                  <JobDetailsDisplay jobDetails={jobData?.jobDetails} />
                  {/* <div className="w-full mt-1">
                            <div className="bg-taskinatorDarkGrey text-taskinatorWhite p-2 rounded">Active Sequences</div>
                            <div></div>
                          </div> */}
                </JobModalDropDownContainer>
                {/* <MdClose size={50} /> */}
              </div>
            </div>
          </div>
          <div className={`flex xl:h-full h-fit xl:grow xl:overflow-auto  scrollbar mt-1`}>
            <div className="w-1/3 min-w-[20rem] flex flex-col h-full overflow-auto ">
              <div className="  h-auto bg-taskinatorOffWhite">
                <JobQuestions tid={jobData?._id} />
              </div>
              <div className="grow overflow-auto flex flex-col border border-taskinatorMedGrey rounded  mt-1">
                {/* <div className=" flex flex-col rounded-lg items-start overflow-auto"> */}
                <div className="flex w-full items-center text-base uppercase whitespace-nowrap bg-taskinatorGreenHover p-4 h-12">
                  <div className="flex w-full">
                    <div className="flex items-center">
                      <div className="flex text-base uppercase items-center text-center w-full justify-start font-semibold text-taskinatorGreen rounded rounded-lg pr-2 ">Tasks</div>
                      {Object.values(selectedTaskIds ?? {}).length > 0 && (
                        <div className="pr-1 flex text-sm">
                          {" "}
                          <div className="bg-taskinatorGreen text-taskinatorWhite mr-1  px-1 rounded-full font-bold">{Object.values(selectedTaskIds ?? {}).length} selected</div> of
                        </div>
                      )}
                      <div className="bg-taskinatorGreen text-taskinatorWhite  px-1.5 rounded-full font-bold text-sm">
                        {
                          jobData.tasks
                            .filter((task) => !task.completed)
                            .filter((task) => (minOnly ? task.assignee.find((assignee) => assignee._id.toString() === appData.workspaceDataV4.user_id) : true)).length
                        }
                      </div>
                    </div>
                    <div className="flex grow justify-end text-xs">
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className={`  text-taskinatorBlack font-medium  px-4 rounded items-center ${!isManagingThisJob && !isManager ? "hidden" : "flex"}`}
                      >
                        <div className="whitespace-nowrap text-xs pr-2 items-center ">Just You</div>
                        <div className="items-center">
                          <StandardToggle
                            defaultValue={false}
                            onInput={(data) => {
                              // setItemsOnList();
                              setIsMineOnly(!data);
                              setSelectedTaskIds({});
                            }}
                          />
                        </div>
                        <div className="whitespace-nowrap text-xs pl-2 items-center ">All</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col p-1 overflow-auto scrollbar">
                  {jobData?.tasks?.length > 0 ? (
                    jobData?.tasks
                      .filter((task) => (minOnly ? task.assignee.find((assignee) => assignee._id.toString() === appData.workspaceDataV4.user_id) : true))
                      .sort((a, b) => {
                        if (a.completed && b.completed) {
                          // Sort completed tasks by date (assuming 'completedDate' is the field)
                          return new Date(b.createdDate) - new Date(a.createdDate);
                        } else if (!a.completed && !b.completed) {
                          // Sort incomplete tasks by taskScore
                          return b.taskScore - a.taskScore;
                        } else {
                          // Ensure completed tasks come after incomplete tasks
                          return a.completed ? 1 : -1;
                        }
                      })
                      .map((task, index) => {
                        return (
                          <div key={task._id} className={`pb-1`}>
                            <TaskDisplay
                              index={index}
                              task={task}
                              jobData={jobData}
                              onClick={() => {}}
                              taskSelected={selectedTask === task._id}
                              setSelectedTaskIds={setSelectedTaskIds}
                              selectedTaskIds={selectedTaskIds}
                              bgOverWrite="bg-taskinatorWhite"
                              // fileSetDetails={jobData?.tasksMap?.fileSetDetails[`${task.fileSetId}-${task.workflowGroupId}`]}
                              // primaryTaskDetails={jobData?.tasksMap?.primaryTaskDetails[`${task.primaryTaskId}-${task.workflowGroupId}`]}
                            />
                          </div>
                        );
                      })
                  ) : (
                    <div className="border border-taskiantorDarkGrey border-2 text-xs rounded py-1 px-2">No Tasks Available - Refer to job scope or submit job</div>
                  )}
                </div>
              </div>
            </div>
            <div className="w-full xl:h-full h-fit flex lg:flex-row flex-col grow lg:overflow-auto ml-1">
              <div className={`flex flex-col xl:h-full grow h-fit xl:overflow-auto scrollbar`}>
                <div className={`flex flex-col xl:h-full h-full  mr-0 scrollbar`}>
                  <div className="flex flex-col grow">
                    <div className="grow overflow-auto flex flex-col bg-taskinatorHoverGrey ">
                      <div className="flex w-full items-center text-base uppercase whitespace-nowrap bg-taskinatorMedGrey p-4 rounded-lg h-12">
                        <div className="flex w-full">
                          <div className="flex items-center w-full">
                            <div className="flex text-base uppercase items-center text-center  justify-start font-semibold text-taskinatorDarkGrey rounded rounded-lg pr-2 w-1/2 ">Folder</div>

                            <div className="flex items-center justify-center w-fit " onClick={(e) => e.stopPropagation()}>
                              <InputV4
                                inputVer={"1"}
                                onInput={inputHandler}
                                customClasses="text-center border-taskinatorTaskinatorBlue text-taskinatorDarkGrey  md:w-96 w-[100%]"
                                defaultValue={inputState.inputs.search.value}
                                placeholder={`Search for files by name, number, rev, extension or tag`}
                                sid="inputs"
                                id="search"
                                validators={[VALIDATOR_MINLENGTH(1)]}
                              />
                            </div>
                            <div className="w-1/2 flex justify-end">
                              {!jobData.completed && isManager && (
                                // <div className="flex w-full justify-end ">
                                <Button label={"Add Another Folder"} onClick={() => setAddFileSet(true)} customClasses={` underline font-normal text-taskinatorDarkGrey  text-xs  h-8  `} />
                                // </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {Object.values(selectedTaskIds ?? {}).length > 0 && (
                        <div className="text-taskinatorRed font-medium pl-1  w-full flex items-center ">
                          {" "}
                          <div className="pr-1">
                            <MdWarning />
                          </div>{" "}
                          Some files not in selected task may be hidden.
                        </div>
                      )}

                      <div className="">
                        <div className="w-full ">
                          {jobData &&
                            Object.values(jobData?.fileMapObject)
                              .sort((a, b) => {
                                if (b.name > a.name) {
                                  return -1;
                                } else if (b.name < a.name) {
                                  return 1;
                                }
                                return 0;
                              })

                              .map((fileSet, index) => {
                                let selectedWorkflowData = [];

                                const totalNewFiles = fileSet.workflows.flat(1).reduce((accumulator, currentFile) => {
                                  return accumulator + Object.keys(currentFile?.newFileCount ?? {}).length;
                                }, 0);

                                return (
                                  <div className="pt-1" key={fileSet.fileSetId}>
                                    <JobModalDropDownContainer
                                      openAtStart
                                      thin
                                      headerContent={
                                        <div>
                                          <div className="  flex my-1 px-1 font-medium ">
                                            {/* <div className="pl-1">Files Set:</div> */}
                                            <div className=" order-taskinatorWhite pr-1">#{fileSet.number} </div>
                                            <div>-</div>
                                            <div className=" order-taskinatorWhite pl-2">{fileSet.name} </div>
                                          </div>
                                          <div className="text-xs flex items-center justify-center">
                                            {fileSet?.fileSetDetails?.settings?.fileTags?.map((tag, index) => {
                                              return (
                                                <div className="border border-taskinatorDarkGrey bg-taskinatorMedGrey font-medium rounded uppercase px-2 text-taskinatorDarkGrey mr-1">{tag.name}</div>
                                              );
                                            })}
                                          </div>
                                          <div className="flex grow justify-end"></div>
                                        </div>
                                      }
                                      textColour={"text-taskinatorDarkGrey"}
                                      borderColour={"border-taskinatorMedGrey"}
                                      backgroundColour={"bg-taskinatorItemBlue border border-taskinatorMedGrey "}
                                    >
                                      <div className="pt-1">
                                        <WorkflowGroup fileSet={fileSet} />
                                      </div>
                                    </JobModalDropDownContainer>
                                  </div>
                                );
                              })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {editJobDetails && (
          <Modal local={() => setEditJobDetails(false)} title="Edit Job Details">
            <div className="md:w-96 w-full">
              <JobDetailsForm
                jId={jobData?._id}
                details={jobData?.jobDetails}
                closeModal={() => setEditJobDetails(false)}
                // onSuccess={getData}
              />
            </div>
          </Modal>
        )}

        {addFileSet && (
          <Modal local={() => setAddFileSet(false)}>
            <AddFileSetToJob tid={jobData?._id} closeModal={() => setAddFileSet(false)} />
          </Modal>
        )}

        {confirmDelete && (
          <Modal local={() => setConfirmDelete()}>
            <ApproveRejectForm
              message={<b className="text-taskinatorRed">Are you sure you want to close this Job - This is Permanent and cannot be undone!</b>}
              // requiredTextMatch={workflowData.name}
              confirmFunction={() => CompleteHandler()}
              rejectFunction={() => setConfirmDelete()}
              confirmLoadingState={initState[1].started}
              error={initState[1].error}
              confirmButtonLabel="CONFIRM CLOSING"
              confirmButtonCSS={"bg-taskinatorRed "}
              requiredTextMatch="CLOSE JOB"
            />
          </Modal>
        )}
      </JobModalContext.Provider>
    );
  }
};

export default JobModalDetailDisplay;
